import { render, staticRenderFns } from "./Submissions.vue?vue&type=template&id=05623560&scoped=true"
import script from "./Submissions.vue?vue&type=script&lang=js"
export * from "./Submissions.vue?vue&type=script&lang=js"
import style0 from "./Submissions.vue?vue&type=style&index=0&id=05623560&prod&scoped=scoped&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05623560",
  null
  
)

export default component.exports